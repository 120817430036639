import styled from 'styled-components'
import { colors } from '../../constants/theme'

export const DeskTopLinkButtonRoot = styled.a`
  padding: 24px 40px 24px 30px;
  border-radius: 37px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);

  font-size: 20px;
  line-height: 30px;
  font-family: StolzlMedium, serif;
  font-weight: 500;

  letter-spacing: 1px;
  color: ${colors.black};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  transition-duration: 0.3s;
  background-color: white;

  transform: scale(0.8);
  

  &:hover {
      color: ${colors.main};
  }

  div {
    width: 25px;
    height: 25px;
    margin-right: 25px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1200px) {
    padding: 14px 20px;
    font-size: 16px;
    svg {
      width: 20px;
      height: 20px;
      padding-right: 15px;
    }
  }

  @media (max-width: 984px) {
    transform: scale(0.7);
    padding: 8px 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`