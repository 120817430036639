import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Link } from '../../i18n'
import { Star } from '../../public/static/svgs/Star'
import { GridItemA, GridPhoto } from './styles'

class GridItem extends PureComponent {
  static propTypes = {
    onStarPress: PropTypes.func,
    item: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
    showStar: PropTypes.bool,
    GridItemComponent: PropTypes.func
  }

  static defaultProps = {
    onStarPress: () => {},
    className: undefined,
    showStar: false,
    GridItemComponent: undefined
  }

  state = {
    favorited: this.props.item.favorited
  }

  onStarPress = (e) => {
    const { item, onStarPress } = this.props
    e.stopPropagation()
    e.preventDefault()
    // if (!item.favorited) 
    this.setState({ favorited: !item.favorited })
    onStarPress(item.id, item.favorited)
  }
  
  render() {
    const {
      item, className, showStar, GridItemComponent
    } = this.props
    const { favorited } = this.state

    const isArtist = item && item.artist !== undefined

    const hrefForHuman = isArtist
      ? `/artist/${item.artist.id}/${item.artist.slug || ''}`
      : `/place/${item.id}/${item.slug || ''}`
    const href = isArtist ? `/artist?id=${item.artist.id}` : `/place?id=${item.id}`

    return (
      <Link key={item.id} href={href} as={hrefForHuman}>
        {GridItemComponent ? <GridItemComponent artist={item} /> : (
          <GridItemA className={className} href={hrefForHuman}>
            <div className="photoContainer">
              <GridPhoto
                className="photo"
                isArtist={isArtist}
                photo={isArtist ? item.artist.photo || item.artist.header_image : item.photo}
              />
              <img src="/static/images/white-angle@2x.png" alt="" />
            </div>
            <div className="starDiv">
              {isArtist && showStar && (
              <button
                className="starButton"
                onClick={this.onStarPress}
              >
                <Star className="star" filled={favorited} />
              </button>
              )}
              {item.start_datetime && item.end_datetime && (
              <span className="time">
                {`${item.start_datetime ? moment(item.start_datetime).format('HH:mm') : ''} - ${
                  item.end_datetime ? moment(item.end_datetime).format('HH:mm') : ''
                }`}
              </span>
              )}
            </div>
            <div className="name">{isArtist ? item.artist.name : item.name}</div>
            {!isArtist && <div className="subName">{item.area_name}</div>}
          </GridItemA>
        )}
      </Link>
    )
  }
}

export default GridItem
