import React from 'react'
import ReactSVG from 'react-svg'
import * as SC from './styled'
interface Props {
  button: {
    id: number
    title: string
    logo: string
    link: string
  }
}
const UmnServiceLink = ({ button }: Props) => {
  const { title, logo, link } = button
  
  return (
    <SC.DeskTopLinkButtonRoot target="_blank" href={link}>
      <ReactSVG src={logo}/>
      {title}
    </SC.DeskTopLinkButtonRoot>
  )
}

export default UmnServiceLink
