/* eslint-disable react/no-danger */
import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import { withTranslation } from '../../i18n'

import TextInput from '../TextInput'
import { ButtonDiv } from '../Button/styles'
import * as Styled from './styles'

const SubscribeForm = ({ t }) => {
  const formRef = useRef()
  const [wasSubmit, setWasSubmit] = useState(false)
  const subscribe = useCallback(
    async (ev) => {
      setWasSubmit(true)
    },
    [formRef.current],
    wasSubmit
  )

  return (
    <Styled.SubscribeFormBase>
      <h2>{t('about:subscribeForm.title')}</h2>

      <Styled.SubscribeForm
        isHidden={wasSubmit}
        ref={formRef}
        onSubmit={subscribe}
        target="unisenderFrame"
        method="POST"
        action="https://cp.unisender.com/ru/subscribe?hash=6x6ij69fcrmfg3fzghapyf3dmy5w4s381nj59aqed7b5e33hg39iy"
      >
        <TextInput
          className="field"
          required
          name="email"
          placeholder="Email"
        />

        <Button

          onPress={subscribe}
          className="submit-btn"
        >{t('about:subscribeForm.buttonText')}</Button>

        <input type="hidden" name="charset" value="UTF-8" />
        <input type="hidden" name="default_list_id" value="20685279" />
        <input type="hidden" name="overwrite" value="2" />
        <input type="hidden" name="is_v5" value="1" />
      </Styled.SubscribeForm>

      <Styled.SuccessMessage isHidden={!wasSubmit}>
        {t('about:subscribeForm.alert')}
      </Styled.SuccessMessage>

      <iframe src="" name="unisenderFrame" frameBorder="0" />
    </Styled.SubscribeFormBase>
  )
}

SubscribeForm.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation(['common', 'about'])(SubscribeForm)
