import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Component } from 'react'
import { Star } from '../../public/static/svgs/Star'
import { Router, withTranslation } from '../../i18n'
import { matchPlural } from '../../api/utils'
import { RecommendedRoutes } from './styles'

class RouteCard extends Component {
  static propTypes = {
    route: PropTypes.instanceOf(Object).isRequired,
    t: PropTypes.func.isRequired,
    onFavorite: PropTypes.func.isRequired,
    styles: PropTypes.arrayOf(String),
    className: PropTypes.string,
    program: PropTypes.bool
  }

  static defaultProps = {
    styles: [],
    className: '',
    program: false
  }

  render() {
    const {
      route: {
        id, favorited, logo, name_preposition, name, artist_count, place_count, duration
      }, t, onFavorite, styles, className, program
    } = this.props

    return (
      <RecommendedRoutes styles={styles[0]} className={className} program={program}>
        <a
          href={`/program/partner/${id}/`}
          className="routeLink"
          ref={(ref) => { this.routeLinkRef = ref }}
          onClick={(e) => {
            if (this.routeLinkRef) {
              e.preventDefault()
              Router.push(`/program?programType=partner&id=${id}`, `/program/partner/${id}/`)
            }
          }}
        >
          <div className="routeCard">
            <div className="cardPhotoDiv">
              <img src={logo} alt="" className="cardPhoto" />
            </div>
            <div className="routeCardMenu">
              <div className="fromDiv">
                <span className="from">{`${t('program:route')} ${name_preposition}`}</span>
                <span className="name">{name}</span>
                <span className="counts">
                  {`${artist_count} ${matchPlural(artist_count,
                    [t('about:artist_one'), t('about:artist_few'), t('about:artist_many')])} / ${
                    place_count} ${matchPlural(place_count,
                    [t('about:place_one'), t('about:place_few'), t('about:place_many')])}`}
                </span>
              </div>
              <div className="starDiv">
                <button onClick={(e) => {
                  onFavorite()
                  e.stopPropagation()
                  e.preventDefault()
                }}
                >
                  <Star className="star" filled={favorited} />
                </button>
                <span className="duration">
                  {`${Math.floor(duration / 60)} ${matchPlural(Math.floor(duration / 60),
                    [t('about:hour_one'), t('about:hour_few'), t('about:hour_many')])}`}
                </span>
              </div>
            </div>
          </div>
        </a>
      </RecommendedRoutes>
    )
  }
}

export default withTranslation(['about', 'program'])(observer(RouteCard))
