/* eslint-disable no-use-before-define */
import styled from 'styled-components'
import { BlogThreshold as Threshold } from '../../constants/theme'

export const SubscribeFormBase = styled.section`
  text-align: center;
  box-sizing: border-box;
  max-width: 770px;
  margin: 0 auto;

  h2 {
    margin-bottom: 50px;
    font-family: StolzlLight;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    color: #080707;

  }

  iframe {
    width: 0;
    height: 0;
  }
`

export const SubscribeForm = styled.form`
  ${_ => _.isHidden ? `display: none;` : 'display: flex;'}

  justify-content: space-between;

  .submit-btn {
    margin-left: 10px;
  }

  @media (max-width: ${Threshold.phone}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .submit-btn {
      margin-left: 0;
      margin-top: 30px;
    }

    .field {
      width: 90%;
      max-width: 500px;
    }
  }
`

export const SuccessMessage = styled.div`
  ${_ => _.isHidden ? `display: none;` : 'display: block;'}

  text-align: center;
  font-weight: bold;
`
