import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from '../../i18n'
import {
  Container, TimeUnitDays, TimeUnit, Digit
} from './styles'

const addZero = value => (value < 10 ? `0${value.toString()}` : value.toString())

const getNameForNumber = (number, baseName) => {
  const cases = [2, 0, 1, 1, 1, 2]
  const numbers = [1, 2, 5]
  return `${baseName}_${
    numbers[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ]
  }`
}

class Timer extends React.PureComponent {
  static propTypes = {
    duration: PropTypes.instanceOf(Object).isRequired,
    onExpire: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    duration: this.props.duration
  };

  decrementSeconds = () => {
    const { duration } = this.state
    const newDuration = duration.clone().subtract(1, 'seconds')
    if (newDuration.asMilliseconds() <= 0) {
      clearInterval(this.interval)
      this.props.onExpire()
      return
    }
    this.setState({ duration: newDuration })
  };

  componentDidMount() {
    this.interval = setInterval(this.decrementSeconds, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { t } = this.props
    const { duration } = this.state

    const data = {
      days: duration.asDays() > 0 ? Math.floor(duration.asDays()) : 0,
      hours: duration.hours() > 0 ? duration.hours() : 0,
      minutes: duration.minutes() > 0 ? duration.minutes() : 0,
      seconds: duration.seconds() > 0 ? duration.seconds() : 0
    }

    let days = data.days.toString()
    if (data.days < 100 && data.days >= 10) {
      days = `0${data.days.toString()}`
    } else if (data.days < 10) {
      days = `00${data.days.toString()}`
    }

    return (
      <Container>
        <TimeUnitDays>
          <div className="digits">
            {days.split('').map((char, index) => (
              <Digit key={index.toString()}>{char}</Digit>
            ))}
          </div>
          <span className="unit">
            {t(getNameForNumber(days, 'timer.days'))}
          </span>
        </TimeUnitDays>
        <TimeUnit>
          <div className="digits">
            {addZero(data.hours)
              .split('')
              .map((char, index) => (
                <Digit key={index.toString()}>{char}</Digit>
              ))}
          </div>
          <span className="unit">
            {t(getNameForNumber(data.hours, 'timer.hours'))}
          </span>
        </TimeUnit>
        <TimeUnit>
          <div className="digits">
            {addZero(data.minutes)
              .split('')
              .map((char, index) => (
                <Digit key={index.toString()}>{char}</Digit>
              ))}
          </div>
          <span className="unit">
            {t(getNameForNumber(data.minutes, 'timer.minutes'))}
          </span>
        </TimeUnit>
        <TimeUnit>
          <div className="digits">
            {addZero(data.seconds)
              .split('')
              .map((char, index) => (
                <Digit key={index.toString()}>{char}</Digit>
              ))}
          </div>
          <span className="unit">
            {t(getNameForNumber(data.seconds, 'timer.seconds'))}
          </span>
        </TimeUnit>
      </Container>
    )
  }
}

export default withTranslation(['about'])(Timer)
