import styled from 'styled-components'
import Colors from '../../constants/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (max-width: 525px) {
    width: 320px;
  }
`

export const TimeUnit = styled.div`
  display: flex;
  margin: 0px 15px;
  flex-direction: column;
  font-family: StolzlLight;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #ffffff;
  text-align: center;
  .digits {
    display: flex;
    flex-direction: row;
  }
  .unit {
    margin-top: 20px;
  }
  @media (max-width: 1000px) {
    margin: 0px 8px;
    font-size: 11px;
    line-height: 16.5px;
    letter-spacing: 1.1px;
    .unit {
      margin-top: 15px;
    }
  }
  @media (max-width: 525px) {
    font-size: 9px;
    margin: 0px 4px;
  }
`

export const TimeUnitDays = styled(TimeUnit)`
  margin-right: 30px;
  @media (max-width: 1000px) {
    margin-right: 16px;
  }
  @media (max-width: 525px) {
    margin-right: 10px;
  }
`

export const Digit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 115px;
  background-color: ${Colors.primary};
  font-family: Montserrat;
  font-weight: bold;
  font-size: 60px;
  text-align: center;
  color: #ffffff;
  border-radius: 9.5px;
  margin: 0px 4px;
  text-align: center;
  padding-left: 3px;
  @media (max-width: 1000px) {
    font-size: 33px;
    width: 38px;
    height: 64px;
    border-radius: 5px;
    margin: 0px 3px;
  }
  @media (max-width: 525px) {
    font-size: 24px;
    width: 28px;
    height: 48px;
    margin: 0px 2px;
  }
`
