import styled from 'styled-components'
import Colors from '../constants/colors'

export const MainInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: bold;
  max-width: 940px;
  z-index: 3;
  margin-top: 0;
  margin-bottom: 70px;
  @media (max-width: 1440px) {
    margin-bottom: 60px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
  @media (max-width: 1000px) {
    padding: 0 50px;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media (max-width: 425px) {
    margin-bottom: -35px;
    padding: 0 10px;
  }
  .content {
    padding: 0;
    @media (max-width: 425px) {
      padding: 0 10px;
    }
  }
  .when {
    margin-bottom: 25px;
    @media (max-width: 425px) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
  .where {
    margin-bottom: 45px;
    @media (max-width: 425px) {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }
  }
  .text {
    a {
      color: ${Colors.primary};
    }
    strong {
      font-family: StolzlMedium, serif;
      font-weight: normal;
    }
    p {
      margin: 0;
    }
    @media (max-width: 425px) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
  .socialButtons {
    margin-top: 40px;
    @media (max-width: 425px) {
      align-self: center;
      display: flex;
      width: 250px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 30px auto auto;
    }
  }
  .buttons {
    margin-top: 40px;
    a + a {
      margin-left: 40px;
    }
    @media (max-width: 1200px) {
      margin-top: 30px;
    }
  }
`

export const SocialLink = styled.a`
  margin-right: 30px;
  @media (max-width: 425px) {
    margin: 0 15px 20px;
  }
`

export const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.3);
  }
`

export const CountersDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  top: 12vw;
  left: 0;
  min-width: 180px;
  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 1441px) {
    margin-top: 3vw;
  }
  .counterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff88;
    height: 9vw;
    max-height: 115px;
    border-radius: 0 5vw 5vw 0;
    padding: 0 15px;
    margin-bottom: 2.5vw;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: #000;
    transition-duration: 0.2s;
    width: 13vw;
    max-width: 150px;
    h2 {
      transition-duration: 0.2s;
    }
    &:hover {
      width: 170px;
      height: calc(9vw + 10px);
      max-height: 125px;
      margin-bottom: calc(2.5vw - 10px);
    }
    &:active {
      opacity: 0.3;
    }
    &:focus {
      opacity: 0.3;
      outline: none;
    }
  }
  span {
    text-align: center;
    font-family: StolzlMedium, serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
  }
`


export const ButtonLinksWrapper = styled.div`
  position: absolute;
  top: 181px;
  left: 60px;

  @media (max-width: 984px) {
    left: 0px;
    top: 125px;
  }
`

export const StreamContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    ${({ image }) => `url(${image})` || ''} no-repeat 50% 38%;
  background-size: cover;
  width: 100vw;
  height: 904px;
  position: relative;
  z-index: -1;
  margin-top: -85px;
  margin-bottom: -30px;
  @media (max-width: 950px) {
    height: 767px;
  }
  .streamDescription {
    max-width: 938px;
    margin-bottom: 60px;
    text-transform: uppercase;
    font-family: StolzlMedium, serif;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
    margin-top: -42px;
    @media (max-width: 950px) {
      max-width: 638px;
      font-size: 16px;
    }
    @media (max-width: 650px) {
      max-width: 324px;
      font-size: 14px;
    }
  }
  .topStreamTear {
    top: -4px;
    position: absolute;
    height: 182px;
    transform: scale(-1);
    @media (min-width: 1920px) {
      width: 100vw;
    }
  }
  .bottomStreamTear {
    bottom: -4px;
    position: absolute;
    height: 182px;
    @media (min-width: 1920px) {
      width: 100vw;
    }
  }
`

export const TimerContainer = styled.div`
  width: 100vw;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-color: #ffffff;
  background-image: url("/static/images/timer-bg@2x.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @media (min-width: 1441px) {
    background-size: 100% 100%;
  }
  @media (max-width: 1000px) {
    height: 550px;
  }
  @media (max-width: 768px) {
    height: 450px;
  }
  @media (max-width: 525px) {
    height: 340px;
  }
  .nextFestText {
    z-index: 1;
    max-width: 850px;
    font-family: StolzlMedium, serif;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    margin-bottom: 60px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    @media (max-width: 1000px) {
      font-size: 16px;
      max-width: 520px;
      margin-bottom: 20px;
    }
    @media (max-width: 525px) {
      text-transform: none;
      max-width: 280px;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0;
      margin-bottom: 10px;
    }
  }
`
export const PostponeContainer = styled.div`
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 200px 50px 170px;
  background-size: cover;
  background-image: url("/static/images/timer-bg@2x.png");
  background-repeat: no-repeat;
  //background-position: 50% 50%;
  background-position: top left;
  @media (min-width: 1441px) {
    background-size: 100% 100%;
  }
  @media (max-width: 1000px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 425px) {
    padding: 130px 20px 110px;
  }
  .postponeText {
    z-index: 1;
    max-width: 940px;
    font-family: StolzlMedium, serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    color: #ffffff;
    @media (max-width: 1000px) {
    }
    @media (max-width: 425px) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
`

export const ContentContainer = styled.div`
  width: 100vw;
  margin: 35px 0;
  ${({ artists }) => (artists ? 'margin-top: 0;padding-top: 35px;' : '')}
  .noRoutesYet {
    text-align: center;
    margin: auto;
    width: 542px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  h2 {
    @media (max-width: 425px) {
      align-self: flex-start;
    }
  }
  .cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin: 45px 0;
    @media (max-width: 650px) {
      margin: 25px 0 10px;
    }
  }
  .card {
    width: 22%;
    margin: 0;
    .name {
      font-family: StolzlMedium, serif;
      letter-spacing: 2px;
      line-height: 30px;
      margin-top: 10px;
      @media (max-width: 1000px) {
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 1px;
      }
    }
    @media (max-width: 650px) {
      width: 48%;
      margin-bottom: 15px;
    }
  }
  .routeCards {
    margin-top: 72px;
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .routeCardContainer {
      width: 22%;
      @media (max-width: 750px) {
        width: 150px;
      }
      .routeCard {
        width: 100%;
      }
    }
    .cardPhoto {
      width: 100%;
    }
    @media (max-width: 750px) {
      margin-top: 24px;
      margin-bottom: 34px;
      width: 314px;
      flex-wrap: wrap;
    }
  }
`

export const RoutesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 60px;
  .mapContainer {
    margin-top: -50px;
    margin-right: 35%;
    @media (max-width: 600px) {
      margin-right: 0;
    }
  }
  .mapBlurred {
    width: 927px;
    height: 646px;
    align-self: baseline;
    background: url("/static/images/main-map-placeholder.png") no-repeat -119px 0px;
    box-shadow: inset -49px 6px 122px 157px #fff;
    @media (max-width: 1000px) {
      background: url(/static/images/main-map-placeholder.png) no-repeat -163px 30px;
      background-size: cover;
      width: 517px;
      height: 529px;
      box-shadow: inset -18px 0px 59px 71px #fff;
    }
    @media (max-width: 600px) {
      background: url(/static/images/main-map-placeholder-small.png) no-repeat -9px
        26px;
      background-size: contain;
      width: 480px;
      height: 327px;
      box-shadow: inset 0 0 71px 79px #fff;
      margin-bottom: 40px;
    }
  }
  .pathImg {
    @media (max-width: 1000px) {
      width: 112px;
    }
    @media (max-width: 600px) {
      width: 90px;
    }
  }
  .pathContainer,
  .mapBlurred {
    position: relative;
  }
  .locationPinDown,
  .locationPinUp {
    @media (max-width: 1000px) {
      width: 30px;
    }
    @media (max-width: 600px) {
      width: 25px;
    }
  }
  .locationPinUp {
    top: -50px;
    left: 142px;
    @media (max-width: 1000px) {
      top: -45px;
      left: 89px;
    }
    @media (max-width: 600px) {
      top: -40px;
      left: 72px;
    }
  }
  .locationPinDown {
    top: 161px;
    left: -15px;
    @media (max-width: 1000px) {
      top: 93px;
      left: -15px;
    }
    @media (max-width: 600px) {
      top: 68px;
      left: -11px;
    }
  }
  .pathComp {
    top: 245px;
    left: 342px;
    @media (max-width: 1000px) {
      top: 192px;
      left: 140px;
    }
    @media (max-width: 600px) {
      top: 111px;
      left: 201px;
    }
  }
  .routeControls h2 {
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 2px;
    margin-bottom: 51px;
    max-width: 693px;
    @media (max-width: 1254px) {
      font-size: 20px;
      max-width: 294px;
    }
    @media (max-width: 600px) {
      text-align: center;
    }
  }
  .routeControls {
    left: 51%;
    @media (max-width: 1254px) {
      left: 59%;
    }
    @media (max-width: 600px) {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      left: unset;
      top: -55px;
      height: 372px;
    }
  }
  .routeControls,
  .pathComp,
  .locationPinDown,
  .locationPinUp {
    position: absolute;
  }
  .uppercase {
    text-transform: uppercase;
  }
`

export const CardPhoto = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  background: url(${props => props.photo
      || (props.isArtist
        ? '/static/images/empty-artist.svg'
        : '/static/images/empty-place.svg')})
    no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
  transition-duration: 0.2s;
  @media (max-width: 1000px) {
    margin-bottom: 0;
    height: 100px;
  }
`

export const MobileAppContainer = styled.div`
  width: 100vw;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;
  @media (max-width: 1000px) {
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    margin-top: 32px;
  }
  .description {
    position: absolute;
    left: 10vw;
    top: 10vw;
    z-index: 2;
    @media (min-width: 1441px) {
      left: calc(50% - 650px);
    }
    @media (max-width: 768px) {
      top: 5vw;
    }
    @media (max-width: 600px) {
      top: 0;
      left: 0;
      position: relative;
      padding: 0 20px;
    }
    .title {
      font-family: StolzlMedium, serif;
      font-size: 50px;
      line-height: 60px;
      color: #000;
      margin-bottom: 25px;
      width: 45%;
      @media (max-width: 1000px) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 2px;
        margin-bottom: 10px;
      }
      @media (max-width: 700px) {
        width: 100%;
      }
    }
    .slug {
      font-family: StolzlMedium, serif;
      font-size: 20px;
      line-height: 30px;
      color: #000;
      text-transform: uppercase;
      margin-bottom: 55px;
      width: 45%;
      @media (max-width: 1000px) {
        font-size: 16px;
        letter-spacing: 2px;
        margin-bottom: 30px;
      }
      @media (max-width: 700px) {
        width: 100%;
      }
    }
    .text {
      margin-bottom: 120px;
      width: 45%;
      @media (max-width: 1000px) {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0;
        margin-bottom: 100px;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    max-width: 100vw;
  }
  .app-links {
    position: absolute;
    left: -10px;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .link {
    transition-duration: 0.2s;
    margin-right: 35px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .magazine {
    margin-top: 0;
    @media (max-width: 600px) {
      position: relative;
    }
    .app-links-mobile {
      display: none;
      position: absolute;
      width: 100%;
      top: 95vw;
      @media (max-width: 600px) {
        display: flex;
        flex-direction: row;
        z-index: 2;
        justify-content: center;
      }
      .link {
        margin: 0 8px;
      }
    }
    .ihponeImg {
      position: absolute;
      z-index: 3;
      width: 27vw;
      top: 0;
      left: 50%;
      @media (max-width: 600px) {
        position: relative;
        top: 0;
        left: calc(50% - 25vw);
        width: 50vw;
      }
    }
    .iphone2Img {
      position: absolute;
      z-index: 1;
      width: 24vw;
      top: 5vw;
      left: calc(50% + 20vw);
      @media (max-width: 600px) {
        top: 8vw;
        height: 82vw;
        width: auto;
        left: auto;
        right: -15px;
        z-index: 2;
      }
    }
    .iphone3Img {
      display: none;
      position: absolute;
      z-index: 2;
      height: 47vw;
      top: 5vw;
      left: 0;
      @media (max-width: 600px) {
        top: 8vw;
        height: 82vw;
        display: inherit;
      }
    }
    .photosImg {
      position: absolute;
      top: 29vw;
      right: 0;
      width: 100vw;
      z-index: 1;
      @media (max-width: 600px) {
        display: none;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 18vw 0 30vw 0;
      background-color: #000;
      color: #fff;
      margin-top: 61vw;
      @media (max-width: 600px) {
        margin-top: 0;
      }
      .umm {
        width: 260px;
        margin-bottom: 45px;
        @media (max-width: 1000px) {
          width: 160px;
          margin-bottom: 25px;
        }
        @media (max-width: 600px) {
          width: 110px;
        }
      }
      .text {
        margin-bottom: 30px;
        max-width: 700px;
        padding: 0 60px;
        @media (max-width: 1000px) {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
        }
        @media (max-width: 600px) {
          padding: 0 20px;
        }
      }
    }
    .whiteGapImgTop {
      display: none;
      right: 0;
      width: 100vw;
      margin-top: 100px;
      margin-bottom: -13vw;
      z-index: 1;
      @media (max-width: 600px) {
        margin-top: 20px;
        transform: scaleY(-1);
        display: inherit;
      }
    }
    .whiteGapImgBottom {
      position: absolute;
      bottom: -1px;
      right: 0;
      width: 100vw;
      z-index: 0;
      @media (max-width: 600px) {
        transform: scaleX(-1);
      }
    }
  }
`

export const SubscribeContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-top: -10vw;
  margin-bottom: 87px;
  z-index: 1;
  padding: 0 30px;

  @media (max-width: 600px) {
    padding: 0 15px;
    margin-bottom: 40px;
  }
`

export const SideProjectsContainer = styled.div`
  width: 100vw;
  margin-bottom: 130px;
  z-index: 1;
  color: #000;
  text-align: center;
  @media (max-width: 1000px) {
    margin-bottom: 80px;
  }
  @media (max-width: 425px) {
    margin-bottom: 10px;
  }
  .title {
    font-family: StolzlMedium, serif;
    align-self: center;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    margin-bottom: 75px;
    text-transform: uppercase;
    @media (max-width: 425px) {
      margin-bottom: 40px;
    }
  }
  img {
    height: 110px;
    margin-bottom: 30px;
    line-height: normal;
    @media (max-width: 1250px) {
      height: 75px;
      margin-bottom: 20px;
    }
    @media (max-width: 425px) {
      height: 100px;
    }
  }
  .longerImage {
    height: 110px;
    margin-bottom: 30px;
    img {
      height: 70px;
      margin-bottom: 0;
    }
    @media (max-width: 1250px) {
      height: 75px;
      margin-bottom: 20px;
      img {
        height: 50px;
      }
    }
    @media (max-width: 425px) {
      height: 50px;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    font-family: StolzlRegular, serif;
    font-size: 16.8px;
    letter-spacing: 0;
    line-height: normal;
    @media (max-width: 1000px) {
      font-size: 10px;
      letter-spacing: 0;
    }
    @media (max-width: 950px) {
      flex-wrap: wrap;
    }
    @media (max-width: 425px) {
      font-size: 15px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;
    transition-duration: 0.2s;
    &:hover {
      transform: scale(1.1);
    }
    @media (max-width: 950px) {
      flex-basis: 50%;
      margin-bottom: 30px;
    }
    @media (max-width: 425px) {
    }
    .text {
      max-width: 260px;
    }
  }
`
